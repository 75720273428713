<template>
  <div id="chinaMapDiv" style="width: 100%; height: 100%"></div>
  <div
    id="contentPopup"
    class="contentPopup"
    :style="`display: ${popupPostion.display}; top: ${popupPostion.top}; left: ${popupPostion.left}`"
    @mouseover="contentMouseover"
    @mouseleave="contentMouseout"
  >
    <div class="pop" style="position: relative">
      <a-card
        class="pop-sub"
        title="公物仓资产详情"
        headStyle="background:#fafafa;"
        size="small"
        style="width: 400px"
        v-if="details"
      >
        <template #extra
          ><div style="font-size: 18px; cursor: pointer" @click="closeClick">
            <CloseOutlined /></div
        ></template>
        <p>资产名称：{{ modalData.name }}</p>
        <p>资产数量：{{ modalData.count }}</p>
        <p>资产价值：{{ modalData.total }} 元</p>
      </a-card>
    </div>
  </div>
</template>

<script>
import chinaJson from './100000_full.json'
import * as echarts from 'echarts'
import chinaOptions from './mapOption.js'

import { CloseOutlined } from '@ant-design/icons-vue'
var chinaChart
export default {
  name: 'chinaMap',
  props: {
    warehouseList: {
      type: Array,
      default: () => {
        return []
      }
    },
    showMarker: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CloseOutlined
  },
  data() {
    return {
      details: true,
      zoom: 0,
      popupPostion: { top: '60px', left: '100px', display: 'none' },
      inMap: false,
      modalData: {},
      targetId: 0
    }
  },
  watch: {
    warehouseList: {
      handler(newValue, oldValue) {
        this.init()
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    closeClick() {
      this.details = false
    },
    init() {
      console.log('init')
      document.getElementById('chinaMapDiv').removeAttribute('_echarts_instance_')
      chinaChart = echarts.init(document.getElementById('chinaMapDiv'), 'map')
      echarts.registerMap('china', chinaJson, {})

      const that = this
      that.restMapData(true)
      chinaChart.on('mouseover', function (target) {})
      chinaChart.on('mousemove', function (target) {
        if (
          target.componentType === 'series' &&
          target.data &&
          target.data.code
        ) {
          that.details = true
          that.popupPostion.display = 'block'
          that.popupPostion.left = target.event.offsetX - 200 + 'px'
          that.popupPostion.top = target.event.offsetY - 200 + 'px'
          that.modalData = target.data
          if (that.targetId !== target.data.index) {
            that.targetId = target.data.index
            that.$emit('updateKey', target.data)
          }
        }
      })
      chinaChart.on('mouseout', function (target) {
        that.inMap = false
        setTimeout(() => {
          if (!that.inMap) that.contentMouseout()
        }, 500)
      })
      chinaChart.getZr().on('click', function (params) {
        const pixelPoint = [params.offsetX, params.offsetY]
        const point = chinaChart.convertFromPixel({ geoIndex: 0 }, pixelPoint)
        console.log('point', point)
      })
      chinaChart.on('georoam', function (params) {
        that.zoom = chinaChart.getOption().geo[0].zoom
        chinaChart.center = chinaChart.getOption().geo[0].center
      })
    },
    contentMouseover() {
      this.inMap = true
    },
    contentMouseout() {
      this.popupPostion.display = 'none'
    },
    changeZoom() {
      chinaOptions.changeZoom(100)
      this.resetMapOptions()
    },
    resetMapOptions() {
      const options = chinaOptions.options()
      chinaChart.setOption(options)
    },
    restMapData(show) {
      if (show) {
        let dataList = []
        dataList = this.warehouseList.filter((item) => {
          return item.show
        })
        chinaOptions.initData(dataList)
      } else {
        chinaOptions.initData([])
      }
      this.resetMapOptions()
    },
    resize() {
      chinaChart.resize()
    }
  }
}
</script>

<style lang="less" scoped>
.contentPopup {
  position: absolute;
  z-index: 1001;
}
</style>
